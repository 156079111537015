import {
  compact,
  eq,
  filter,
  find,
  findIndex,
  get,
  getOr,
  head,
  identity,
  includes,
  keys,
  map,
  negate,
  omit,
  pick,
  pipe,
  placeholder,
  size,
  values,
} from 'lodash/fp'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { everyFalse, passProps } from '@masterplandev/utils'

import { Topic } from '@/api/generated-api-and-types'
import { STATUS_COMPLETED, STATUS_LOCKED } from '@/core/constants/constants'
import {
  createRouteParamSelector,
  generateBasicSelectors,
} from '@/core/selectors'
import cachedKeyCreator from '@/core/utils/redux/cachedKeyCreator'
import createMemoizeByIdentifierSelector from '@/core/utils/redux/createMemoizeByIdentifierSelector'
import { currentLectureUrlSelector } from '@/dashboard/selectors'

export const rootSelector = get('topic')

export const topicSlugSelector = createRouteParamSelector('topic')

export const topicSelectors = generateBasicSelectors(
  createCachedSelector([rootSelector, passProps], (topics, { topic }) =>
    getOr({}, `/topics/${topic}`, topics),
  )(cachedKeyCreator),
)

export const topicFullCoursesSelector = createSelector(
  [rootSelector, passProps],
  (topics, slugToCountMap: Record<string, number>) =>
    pipe([
      map((slug) => get([`/topics/${slug}`, 'data'], topics)),
      compact,
      filter((topic: Topic) =>
        eq(size(get('lectures', topic)), slugToCountMap[topic.slug ?? '']),
      ),
    ])(keys(slugToCountMap)),
)

export const topicSelector = createSelector(
  [rootSelector, topicSlugSelector, passProps],
  (topics, topic, { topic: slug }) =>
    getOr({}, `/topics/${topic ?? slug}`, topics),
)

export const dataSelector = createSelector(topicSelector, getOr({}, 'data'))
export const metaSelector = createSelector(topicSelector, omit('data'))
export const fetchingSelector = createSelector(
  metaSelector,
  getOr(false, 'fetching'),
)
export const fetchedSelector = createSelector(
  metaSelector,
  getOr(false, 'fetched'),
)

export const requiresFetchSelector = createSelector(
  metaSelector,
  pipe([pick(['fetched', 'failed', 'fetching']), values, everyFalse]),
)

export const lecturesSelector = createSelector(
  dataSelector,
  getOr([], 'lectures'),
)

export const titleSelector = createSelector(dataSelector, get('title'))

export const durationSelector = createSelector(dataSelector, get('duration'))

export const topicProgressStatusSelector = createSelector(
  dataSelector,
  get('progress.status'),
)

export const totalLecturesSelector = createSelector(lecturesSelector, size)

export const nextLectureSelector = createSelector(
  lecturesSelector,
  (lectures) =>
    find(
      pipe([
        get('progress.status'),
        negate(includes(placeholder, [STATUS_LOCKED, STATUS_COMPLETED])),
      ]),
      lectures,
    ) ||
    head(lectures) ||
    {},
)

export const nextLectureUrlSelector = createSelector(
  nextLectureSelector,
  dataSelector,
  passProps,
  ({ slug }, topic, props) =>
    currentLectureUrlSelector.resultFunc({ slug, topic }, false, props),
)

export const lectureIndexSelector = createSelector(
  [lecturesSelector, passProps],
  (lectures, { match }) => findIndex({ slug: match.params.lecture }, lectures),
)

export const topicIdSelector = createSelector(dataSelector, get('id'))

export const lecturesFromPropsSelector = createSelector(
  topicSelectors.data,
  getOr([], 'lectures'),
)

export const topicLectureSlugsSelector = createMemoizeByIdentifierSelector({
  identifiers: ['slug'],
})(createSelector(lecturesFromPropsSelector, map('slug')), identity)
