import { createActions } from 'redux-actions'

import toMatchParams from '@/lecture/utils/toMatchParams'

import { requiresFetchSelector } from './selectors'

const actions = createActions({
  TOPIC: {
    GET: (topic) => ({
      request: {
        url: `/topics/${topic}`,
      },
    }),
    CLEAR_ALL: () => ({}),
  },
})

actions.topic.getIfRequired =
  ({ topic }) =>
  (dispatch, getState) => {
    const matchParams = toMatchParams({ topic })

    if (requiresFetchSelector(getState(), matchParams)) {
      return dispatch(actions.topic.get(topic))
    }

    return Promise.resolve()
  }

export default actions.topic
