import { QueryClient } from '@tanstack/react-query'

import { env } from '@/env'

export const queryClient =
  env.MODE === 'test'
    ? new QueryClient({
        // Silence react-query network errors in tests
        /* eslint-disable no-console */
        logger: {
          log: console.log,
          warn: console.warn,
          error: env.MODE === 'test' ? () => {} : console.error,
        },
        /* eslint-enable no-console */
        defaultOptions: { queries: { retry: false } },
      })
    : new QueryClient()
