/* eslint-disable jsdoc/require-example */

/**
 * Identify if a webapp is being loaded inside an iframe or directly into the browser window?
 */
export function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
