import { captureMessage } from '@sentry/react'
import { join, merge, replace, startsWith } from 'lodash/fp'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import parse from 'url-parse'

import { anyTrue, everyTrue, silentAttempt } from '@masterplandev/utils'

import { useAuth } from '@/auth/hooks/useAuth'
import {
  SENTRY_SECTIONS,
  URL_COMPANY,
  URL_DASHBOARD,
  URL_DASHBOARD_OLD,
  URL_LOGIN,
  URL_ROOT,
  URL_SIGNUP,
  URL_SIGNUP_EMAIL,
  URL_SIGNUP_POLL,
  URL_SIGNUP_PROFILE,
  URL_SIGNUP_WELCOME,
} from '@/core/constants/constants'
import {
  FEATURE_SIGNUP_EMAIL_REQUIRED,
  FEATURE_SIGNUP_PROFILE,
} from '@/core/constants/features'
import keycloak from '@/core/keycloak'
import { debugSelector } from '@/core/selectors'
import { queryParamLangSelector } from '@/locale/selectors'
import { isSignupPollSubmittedSelector } from '@/poll/selectors'
import { useCurrentUserPrivacyPolicyStatus } from '@/user/queries/useCurrentUserPrivacyPolicyStatus'
import {
  anonymousPathnameSelector,
  createIsFeatureSelector,
  isManagingRoleSelector,
  isStudentSelector,
  requiresLoginSelector,
  loadingSelector as userLoadingSelector,
  dataSelector as userSelector,
} from '@/user/selectors'

function AppManagerRedirectPure({
  debug,
  requiresLogin,
  anonymousPath,
  location,
  isStudent,
  isManagingRole,
  user,
  pollSubmitted,
  children,
  langQueryParam,
  signupProfileFeature,
  signupEmailRequiredFeature,
}) {
  const { isAuthenticated } = useAuth()
  const privacyPolicyStatus = useCurrentUserPrivacyPolicyStatus()

  const getSignupStepPath = () => {
    const { pathname } = location

    if (anyTrue(!isStudent, startsWith(URL_COMPANY, pathname))) {
      return null
    }

    if (everyTrue(startsWith(URL_SIGNUP, pathname), !langQueryParam)) {
      return URL_SIGNUP_WELCOME
    }

    if (pathname === URL_SIGNUP_WELCOME) {
      // Don't let redirect user outside of welcome page
      return URL_SIGNUP_WELCOME
    }

    // This redirection might happen in case of Masterplan being used through the SCORM package.
    // Keycloak id (and user id) is created, but the user does not have any email assigned to themself.
    const emailIsMissing = user?.id && !user.email && !user.email_pending_update
    if (emailIsMissing && signupEmailRequiredFeature) {
      return URL_SIGNUP_EMAIL
    }

    if (signupProfileFeature && (!user?.first_name || !user?.last_name)) {
      return URL_SIGNUP_PROFILE
    }

    // TODO: [MP-6450] Possibly we need a new signup step for privacy policy
    // TODO: check. For now it was put inside poll step for simplicity and
    // TODO: contract short time requirements.
    if (privacyPolicyStatus.data === 'required') {
      return URL_SIGNUP_POLL
    }

    if (pollSubmitted === false) {
      return URL_SIGNUP_POLL
    }

    return null
  }

  const getRedirectPath = () => {
    const { pathname, search, hash } = location

    if (debug) {
      return null
    }

    if (requiresLogin) {
      return URL_LOGIN
    }

    if (isAuthenticated) {
      const signupStep = getSignupStepPath()

      if (signupStep) {
        const currPath = join('', [pathname, search, hash])
        if (
          !startsWith(URL_SIGNUP, currPath) &&
          currPath !== URL_ROOT &&
          currPath !== URL_LOGIN
        ) {
          silentAttempt(() => {
            localStorage.afterLogin = currPath
          })
        }

        return signupStep
      }

      if (startsWith(URL_COMPANY, pathname) && isStudent && !isManagingRole) {
        return URL_DASHBOARD
      }

      const isAfterSignup =
        startsWith(URL_SIGNUP_POLL, pathname) && pollSubmitted

      if (anonymousPath || isAfterSignup) {
        const afterLogin = silentAttempt(() => localStorage.afterLogin)
        if (afterLogin) {
          delete localStorage.afterLogin
          return afterLogin
        }

        if (isStudent) {
          return URL_DASHBOARD
        }

        if (isManagingRole) {
          return URL_COMPANY
        }
      }

      if (isManagingRole && !isStudent) {
        return URL_COMPANY
      }
    }

    /*
    Temporary redirect to provide backward compatibility until
    new path to dashboard gets propagated
     */
    if (startsWith(URL_DASHBOARD_OLD, pathname)) {
      return replace(URL_DASHBOARD_OLD, URL_DASHBOARD, pathname)
    }

    return null
  }

  const redirect = getRedirectPath()

  if (startsWith(URL_LOGIN, redirect)) {
    if (keycloak.authenticated) {
      captureMessage('Unexpected logout', {
        level: 'warning',
        tags: { section: SENTRY_SECTIONS.keycloak },
        extra: { token: keycloak.token },
      })
      keycloak.logout()
    } else {
      keycloak.login({ loginHint: user?.email })
    }

    return null
  }

  const parsedUrl = parse(redirect)
  const mergedSearch = queryString.stringify(
    merge(
      queryString.parse(location.search),
      queryString.parse(parsedUrl.query),
    ),
  )

  if (redirect && !startsWith(redirect, location.pathname)) {
    return (
      <Redirect
        to={{
          pathname: parsedUrl.pathname,
          search: mergedSearch,
          hash: parsedUrl.hash,
        }}
      />
    )
  }
  return children
}

AppManagerRedirectPure.propTypes = {
  debug: PropTypes.bool,
  requiresLogin: PropTypes.bool.isRequired,
  anonymousPath: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
  isStudent: PropTypes.bool.isRequired,
  isManagingRole: PropTypes.bool.isRequired,
  user: PropTypes.object,
  pollSubmitted: PropTypes.bool,
  children: PropTypes.node.isRequired,
  langQueryParam: PropTypes.string,
  signupProfileFeature: PropTypes.bool,
  signupEmailRequiredFeature: PropTypes.bool,
}

AppManagerRedirectPure.defaultProps = {
  debug: false,
  user: {},
  pollSubmitted: null,
  langQueryParam: null,
  signupProfileFeature: true,
  signupEmailRequiredFeature: false,
}

const mapStateToProps = createStructuredSelector({
  debug: debugSelector,
  loading: userLoadingSelector,
  isStudent: isStudentSelector,
  isManagingRole: isManagingRoleSelector,
  requiresLogin: requiresLoginSelector,
  anonymousPath: anonymousPathnameSelector,
  user: userSelector,
  pollSubmitted: isSignupPollSubmittedSelector,
  langQueryParam: queryParamLangSelector,
  signupProfileFeature: createIsFeatureSelector(FEATURE_SIGNUP_PROFILE),
  signupEmailRequiredFeature: createIsFeatureSelector(
    FEATURE_SIGNUP_EMAIL_REQUIRED,
  ),
})

// eslint-disable-next-line import/prefer-default-export
export const AppManagerRedirect = compose(
  withRouter,
  connect(mapStateToProps),
)(AppManagerRedirectPure)
