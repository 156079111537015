import {
  compact,
  endsWith,
  eq,
  every,
  filter,
  find,
  flatMap,
  fromPairs,
  get,
  getOr,
  identity,
  isEmpty,
  isEqual,
  join,
  map,
  mapValues,
  merge,
  negate,
  omit,
  pipe,
  reduce,
  replace,
  split,
  toNumber,
  uniqBy,
} from 'lodash/fp'
import createCachedSelector from 're-reselect'
import { Selector, createSelector } from 'reselect'
import subtitlesParser from 'subtitles-parser'

import { everyTrue, passProps } from '@masterplandev/utils'

import { CurrentLecture } from '@/api/generated-api-and-types'
import {
  API_ERROR_LECTURE_FEEDBACK_NOT_FOUND,
  STATUS_UNLOCKED,
  TOPIC_TYPE_CHANNEL,
  TOPIC_TYPE_COURSE,
  TOPIC_TYPE_INTERNAL,
  URL_SUFFIX_BOOKMARKS,
  URL_SUFFIX_FEEDBACK,
  URL_SUFFIX_TRANSCRIPT,
} from '@/core/constants/constants'
import { generateBasicSelectors, pathnameSelector } from '@/core/selectors'
import type { ResourceState } from '@/core/types/ResourceState'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import cachedKeyCreator from '@/core/utils/redux/cachedKeyCreator'
import createMemoizeByIdentifierSelector from '@/core/utils/redux/createMemoizeByIdentifierSelector'
import {
  currentLectureDataSelector,
  currentLectureUrlSelector,
} from '@/dashboard/selectors'
import { dataSelector as topicSelector } from '@/topic/selectors'

import { LECTURE_TYPE_VIDEO } from '../constants'
import { Lecture } from '../types/Lecture'
import { LectureState } from '../types/LectureState'
import buildUniversalLectureApiUrl from '../utils/buildUniversalLectureApiUrl'

type State = {
  lectures: LectureState
}

export const rootSelector: Selector<State, LectureState> = getOr({}, 'lectures')

export const lectureSelector = createSelector<
  any,
  any,
  any,
  ResourceState<Lecture>
>([rootSelector, passProps], (lectures, { match: { params } }) =>
  getOr({}, buildUniversalLectureApiUrl(params), lectures),
)

export const lectureSelectors = generateBasicSelectors(
  createCachedSelector(
    [rootSelector, passProps],
    (root, { match: { params } }) =>
      getOr({}, buildUniversalLectureApiUrl(params), root),
  )(cachedKeyCreator),
)

export const lectureIdSelector = createCachedSelector(
  [lectureSelectors.data],
  get('id'),
)(cachedKeyCreator)

export const lectureChatbotNameSelector = createCachedSelector(
  [lectureSelectors.data],
  get('name'),
)(cachedKeyCreator)

export const lectureDataByIdentifierSelector =
  createMemoizeByIdentifierSelector({ identifiers: ['slug', 'uid', 'id'] })(
    lectureSelectors.data,
    identity,
  )

export const bookmarksRawSelector = createSelector<
  any,
  any,
  any,
  ResourceState
>([rootSelector, passProps], (lectures, { match: { params } }) =>
  getOr(
    {},
    buildUniversalLectureApiUrl(params, { suffix: URL_SUFFIX_BOOKMARKS }),
    lectures,
  ),
)

export const metaSelector = createSelector(lectureSelector, omit('data'))

export const dataSelector = createSelector(
  lectureSelector,
  getOr({} as Lecture, 'data'),
)

export const typeSelector = createSelector(dataSelector, getOr('', 'type'))

export const bodySelector = createSelector(dataSelector, get('body'))

export const titleSelector = createSelector(dataSelector, get('title'))

export const hasQuizSelector = createSelector(dataSelector, get('has_quiz'))

export const authorsSelector = createSelector(dataSelector, get('authors'))

export const materialsSelector = createSelector(dataSelector, get('materials'))

export const glossarySelector = createSelector(dataSelector, get('glossary'))

export const feedbackDisabledSelector = createSelector(
  dataSelector,
  get('feedback_disabled'),
)

export const feedbackRawSelector = createSelector<any, any, any, ResourceState>(
  [rootSelector, passProps],
  (lectures, { match: { params } }) =>
    getOr(
      {},
      buildUniversalLectureApiUrl(params, { suffix: URL_SUFFIX_FEEDBACK }),
      lectures,
    ),
)

export const lectureFeedbackSelectors =
  generateBasicSelectors(feedbackRawSelector)

// Lecture feedback not supported for learnpaths.
const originalLectureFeedbackLoadingSelector = lectureFeedbackSelectors.loading
lectureFeedbackSelectors.loading = createSelector(
  [originalLectureFeedbackLoadingSelector, passProps],
  (feedbackLoading, { learnpathId }) => feedbackLoading && !learnpathId,
) as (typeof lectureFeedbackSelectors)['loading']

export const feedbackDataSelector = createSelector(
  feedbackRawSelector,
  get('data'),
)

export const feedbackMetaSelector = createSelector(
  feedbackRawSelector,
  omit('data'),
)

export const feedbackRequiresFetchSelector = createSelector(
  feedbackMetaSelector,
  ({ fetched, failed, fetching }) =>
    every(identity, [!fetching, !fetched, !failed]),
)

export const feedbackErrorSelector = createSelector(
  feedbackMetaSelector,
  get('error'),
)

export const feedbackNotFoundSelector = createSelector(
  feedbackErrorSelector,
  pipe([get('message'), isEqual(API_ERROR_LECTURE_FEEDBACK_NOT_FOUND)]),
)

export const obtainFeedbackSelector = createSelector(
  [feedbackDisabledSelector, feedbackNotFoundSelector],
  (feedbackDisabled, feedbackNotFound) => feedbackNotFound && !feedbackDisabled,
)

// Copied from https://github.com/tsur/vtt-to-srt/blob/b0df8544227f67d7cab638487d465755598f83c1/index.es6#L11-L15
/* eslint-disable no-useless-escape */
export const vtt2srt = pipe([
  replace(/(WEBVTT\s*(FILE)?.*?)(\r\n)*/g, ''),
  replace(
    /(\d{2}:\d{2}:\d{2})\.(\d{3}\s+)\-\-\>(\s+\d{2}:\d{2}:\d{2})\.(\d{3}\s*)/g,
    '$1,$2-->$3,$4',
  ),
  replace(/\<.+\>(.+)/g, '$1'),
  replace(/\<.+\>(.+)\<.+\/\>/g, '$1'),
  (output) => `${output}\r\n`,
])
/* eslint-enable no-useless-escape */

export const hasDefinitionsSelector = createSelector(
  glossarySelector,
  negate(isEmpty),
)

export const hasTranscriptSelector = createSelector(
  dataSelector,
  pipe([get('transcript'), negate(isEmpty)]),
)

export const lectureTranscriptSelector = createMemoizeByIdentifierSelector({
  identifiers: ['uid', 'id'],
})(dataSelector, (lecture) => {
  let offset = 0
  return pipe([
    get('transcript'),
    vtt2srt,
    subtitlesParser.fromSrt,
    map(({ startTime, text }) => {
      const line = {
        // Second replace for escaping markdown [...] link syntax.
        text: text.replace(/(\r)?\n/g, ' ').replace(/(\[.*])/g, '`$1`'),
        timestamp: startTime.replace(
          /^00:([0-9]{2}:[0-9]{2})[.,][0-9]{3}/,
          '$1',
        ),
        offset,
      }
      offset += text.length
      return line
    }),
  ])(lecture)
})

export const requiresFetchSelector = createSelector(
  [metaSelector, dataSelector, passProps],
  ({ fetched, failed, fetching }, { slug }, { match: { params } }) =>
    every(identity, [
      !fetching,
      !fetched,
      !failed,
      slug !== get('lecture', params),
    ]),
)

export const bookmarksMetaSelector = createSelector(
  bookmarksRawSelector,
  omit('data'),
)
export const bookmarksDataSelector = createSelector(
  bookmarksRawSelector,
  get('data'),
)
export const bookmarksFetchingSelector = createSelector(
  bookmarksMetaSelector,
  getOr(false, 'fetching'),
)
export const bookmarksRequiresFetchSelector = createSelector(
  bookmarksMetaSelector,
  ({ fetched, failed, fetching }) =>
    every(identity, [!fetching, !fetched, !failed]),
)

export const bookmarksSelector = createSelector(
  bookmarksDataSelector,
  pipe([getOr([], 'bookmarks'), uniqBy('id')]),
)

export const bookmarkSelector = createSelector(
  [bookmarksSelector, passProps],
  (bookmarks, { match: { params } }) => find({ id: params.id }, bookmarks),
)

export const slideshowNotesSelector = createSelector(
  dataSelector,
  pipe([
    get('body'),
    split('\n'),
    compact,
    reduce((result: Record<string, any>, value: string) => {
      const newSection = value.trim().match(/^([0-9]+):$/)
      if (newSection) {
        const id = toNumber(newSection[1]) - 1
        return merge(result, { _: id, [id]: [] })
      }

      const id = result._
      return merge(result, {
        [id]: result[id].concat(value),
      })
    }, {}),
    mapValues(join('\n')),
    omit('_'),
  ]),
)

export const definitionBookmarksSelector = createSelector(
  bookmarksSelector,
  pipe([
    filter({ icon: 'definition' }),
    map(({ id, title }) => [title, id]),
    fromPairs,
  ]),
)

export const highlightBookmarksSelector = createSelector(
  bookmarksSelector,
  filter({ icon: 'highlight' }),
)

export const highlightsSelector = createSelector(
  highlightBookmarksSelector,
  (bookmarks) =>
    filter(
      'text',
      flatMap(
        ({
          id,
          title,
          position,
        }: {
          id: string
          title: string
          position: number
        }) => {
          let textOffset = 0
          let newLinesOffset = 0
          return map((text) => {
            const bookmark = {
              id,
              text,
              position: position !== null ? position + textOffset : null,
              newLinesOffset,
            }
            textOffset += text.length
            newLinesOffset += 2
            return bookmark
          }, title.split('\n'))
        },
        bookmarks,
      ),
    ),
)

export const isVideoLectureTranscriptTabSelector = createSelector(
  [typeSelector, pathnameSelector],
  (type, pathname) =>
    everyTrue([
      type === LECTURE_TYPE_VIDEO,
      endsWith(`/${URL_SUFFIX_TRANSCRIPT}`, pathname),
    ]),
)

export const nextLectureUrlSelector = createSelector(
  [currentLectureUrlSelector, currentLectureDataSelector, topicSelector],
  (currentLectureUrl, currentLecture: CurrentLecture, topic) => {
    function getTopicType() {
      return topic.is_internal ? TOPIC_TYPE_INTERNAL : topic.type
    }

    function getNextTopicType() {
      if (!currentLecture.topic) {
        return null
      }

      const { type, is_internal } = currentLecture.topic

      if (is_internal) {
        return TOPIC_TYPE_INTERNAL
      }

      return type
    }

    const topicType = getTopicType()
    const nextTopicType = getNextTopicType()

    const isCurrentTypeInternal = topicType === TOPIC_TYPE_INTERNAL
    const isCurrentInsideCourse = topicType === TOPIC_TYPE_COURSE

    if (isCurrentTypeInternal) {
      const nextInternalLecture = find(
        pipe([get('progress.status'), eq(STATUS_UNLOCKED)]),
        topic.lectures,
      )

      if (nextInternalLecture) {
        return buildLectureLink({
          topic: topic.slug,
          lecture: nextInternalLecture.slug,
        })
      }

      return null
    }

    if (isCurrentInsideCourse && nextTopicType === TOPIC_TYPE_CHANNEL) {
      return null
    }

    return currentLectureUrl
  },
)
