import { connectRouter } from 'connected-react-router'
import {
  cond,
  defaultTo,
  pipe,
  startsWith,
  stubFalse,
  stubTrue,
} from 'lodash/fp'
import { combineReducers } from 'redux'
import { ignoreActions } from 'redux-ignore'

import { getWithFallback } from '@masterplandev/utils'

import bookmarked from '@/bookmarked/reducers'
import channels from '@/channels/reducer'
import company from '@/company/reducer'
import core from '@/core/reducer'
import dashboard from '@/dashboard/reducer'
import learngroup from '@/learngroup/reducer'
import learnpathsAssigned from '@/learnpaths-assigned/reducer'
import mediaSummary from '@/learnpaths-media/reducers/mediaSummary.reducer'
import learnpaths from '@/learnpaths/reducer'
import lectures from '@/lecture/reducer'
import locale from '@/locale/reducer'
import poll from '@/poll/reducer'
import quiz from '@/quiz/reducer'
import scorm from '@/scorm/reducer'
import signup from '@/signup/reducer'
import topic from '@/topic/reducer'
import user from '@/user/reducer'

const getUrl = getWithFallback([
  'payload.url',
  'options.url',
  'payload.options.url',
  'payload.request.url',
  'meta.previousAction.payload.options.url',
  'meta.previousAction.payload.request.url',
])

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    bookmarked,
    channels,
    company,
    core,
    dashboard,
    learngroup,
    learnpaths: ignoreActions(
      learnpaths,
      pipe(
        getUrl,
        defaultTo('/learnpaths'),
        cond([
          [startsWith('/learnpaths/assigned'), stubTrue],
          [startsWith('/lectures'), stubTrue],
          [stubTrue, stubFalse],
        ]),
      ),
    ),
    learnpathsAssigned: ignoreActions(
      // @ts-ignore
      learnpathsAssigned,
      pipe(
        getUrl,
        defaultTo('/learnpaths/assigned'),
        cond([
          [startsWith('/learnpaths/assigned'), stubFalse],
          [startsWith('/learnpaths'), stubTrue],
          [stubTrue, stubFalse],
        ]),
      ),
    ),
    mediaSummary,
    lectures: ignoreActions(
      lectures,
      pipe(
        getUrl,
        defaultTo('/lectures'),
        cond([
          [startsWith('/learnpaths/assigned'), stubFalse],
          [startsWith('/learnpaths'), stubTrue],
          [stubTrue, stubFalse],
        ]),
      ),
    ),
    locale,
    poll,
    quiz,
    scorm,
    signup,
    topic,
    user,
  })
