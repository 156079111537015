import {
  castArray,
  forEach,
  isArray,
  isEmpty,
  keys,
  map,
  omit,
  reduce,
} from 'lodash/fp'
import { createActions } from 'redux-actions'
import * as v from 'valibot'

import { urlParamsToObject } from '@/core/utils/urlParamsToObject'

import { mediaSummaryFilterSelector } from '../selectors/mediaSummary.selectors'

const mediaSummaryFiltersSchema = v.partial(
  v.object({
    search: v.string(),
  }),
)

const filtersSchema = v.partial(
  v.object({
    owner: v.union([v.literal('me')]),
    type: v.array(v.union([v.literal('in_person'), v.literal('online')])),
    user: v.special((val) =>
      typeof val === 'string' ? /^.+:.+$/.test(val) : false,
    ),
    state: v.union([
      v.literal('overdue'),
      v.literal('unassigned'),
      v.literal('active'),
      v.literal('in_progress'),
    ]),
    group: v.special((val) =>
      typeof val === 'string' ? /^.+:.+$/.test(val) : false,
    ),
  }),
)

const paramsSchema = v.merge([filtersSchema, mediaSummaryFiltersSchema])

const setNewSearch = (searchParams, history) => {
  if (searchParams.size === 0) {
    history.push(window.location.pathname)
  } else {
    history.push({
      search: searchParams.toString(),
    })
  }
}

// Function sanitizes search parameters against existing filter schema. Faulty URL
// entries are ignored. Valibot is used to validate the schema.
const sanitizeParams = (
  searchParams = new URLSearchParams(window.location.search),
) => {
  const parsedString = urlParamsToObject(searchParams)
  const arrayAssured = reduce(
    (acc, key) => {
      if (paramsSchema.entries[key].wrapped.type === 'array') {
        return { ...acc, [key]: castArray(parsedString[key]) }
      }

      return { ...acc, [key]: parsedString[key] }
    },
    {},
    keys(paramsSchema.entries),
  )

  const parsedFilter = v.safeParse(paramsSchema, arrayAssured)

  if (parsedFilter.success) {
    return parsedFilter.output
  }

  return omit(map('path[0].key', parsedFilter.issues), parsedFilter.output)
}

const actions = {
  mediaSummary: createActions(
    'CLEAR_SELECTED_ROWS',
    'RESET',
    'SET_DETAILS_ANIMATION_MEDIA_ID',
    'SET_DIALOG',
    'SET_FILTERS',
    'SET_MEDIA_ID',
    'SET_PAGE_INDEX',
    'SET_PAGE_SIZE',
    'SET_PAGE_SORTING',
    'SET_SHOW_ONLY_SELECTED',
    'SET_TAB',
    'TOGGLE_ROW_SELECTION',
    'REMOVE_ROW_SELECTION',
    'TOGGLE_SHOW_ONLY_SELECTED',
  ),
}

actions.mediaSummary.appendFilter =
  (key, newValue, history) => (dispatch, getState) => {
    const searchParams = new URLSearchParams(window.location.search)
    const currentFilters = mediaSummaryFilterSelector(getState(), {
      filter: key,
    })

    if (!currentFilters || isArray(currentFilters)) {
      searchParams.append(key, newValue)
      setNewSearch(searchParams, history)
      dispatch(actions.mediaSummary.setFilters(sanitizeParams(searchParams)))
    }
  }

actions.mediaSummary.updateFilter = (key, newValue, history) => (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search)

  if (isArray(newValue)) {
    searchParams.delete(key)
    forEach((value) => searchParams.append(key, value), newValue)
  } else {
    searchParams.set(key, newValue)
  }

  setNewSearch(searchParams, history)
  dispatch(actions.mediaSummary.setFilters(sanitizeParams(searchParams)))
}

actions.mediaSummary.removeFilter =
  (key, oldValue, history) => (dispatch, getState) => {
    const searchParams = new URLSearchParams(window.location.search)

    const values = mediaSummaryFilterSelector(getState(), { filter: key })
    if (isArray(values) && oldValue) {
      const newArray = values.filter((item) => item !== oldValue)

      if (isEmpty(newArray)) {
        searchParams.delete(key)
      } else {
        searchParams.set(key, newArray)
      }
    } else {
      searchParams.delete(key)
    }

    setNewSearch(searchParams, history)
    dispatch(actions.mediaSummary.setFilters(sanitizeParams(searchParams)))
  }

actions.mediaSummary.clearFilters = (history) => (dispatch) => {
  setNewSearch(new URLSearchParams(), history)
  dispatch(actions.mediaSummary.setFilters({}))
}

export default actions.mediaSummary
