import posthog from 'posthog-js'
import { createActions } from 'redux-actions'

import { eventTracker, silentAttempt } from '@masterplandev/utils'

import { LOCAL_STORAGE_ITEMS } from '@/core/constants/constants'
import keycloak from '@/core/keycloak'

const actions = createActions(
  {
    USER: {
      GET: () => ({
        options: { returnRejectedPromiseOnError: false },
        request: {
          url: '/users/current',
        },
      }),
      PUT: [
        (data) => ({
          request: {
            url: '/auth/user',
            method: 'PUT',
            data,
          },
        }),
        (data, meta) => ({ ...meta }),
      ],
      EMAIL: {
        PUT: (data) => ({
          request: {
            url: '/scorm/user/email',
            method: 'PUT',
            data,
          },
        }),
      },
      UPDATE_EMAIL: (email) => email,
    },
  },
  'UPDATE_ACCOUNT',
)

const logout = () =>
  function userLogout() {
    silentAttempt(() => {
      localStorage.removeItem(LOCAL_STORAGE_ITEMS.masterplan)
      localStorage.removeItem(LOCAL_STORAGE_ITEMS.seen_notifications)
    })

    eventTracker.reset()
    posthog.reset()

    if (keycloak.authenticated) {
      const currentUrl = new URL(window.location.href)
      keycloak.logout({ redirectUri: currentUrl.origin })
    } else {
      window.location.pathname = '/'
    }
  }

export default {
  ...actions.user,
  updateAccount: actions.updateAccount,
  logout,
}
