import LogRocket from 'logrocket'

import { eventTracker } from '@masterplandev/utils'

import { TrackingEvent } from './types/TrackingEvent'

window.dataLayer = window.dataLayer || []

export const track = <T extends keyof TrackingEvent>(
  event: T,
  params: TrackingEvent[T],
) => {
  eventTracker.track(event, {
    $referrer: document.referrer,
    $current_url: window.location.href,
    ...params,
  })
  LogRocket.log(`mixpanel ${event}`, params)
  window.dataLayer.push({
    event,
    ...params,
  })
}

export default track
