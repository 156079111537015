/* eslint-disable jsdoc/require-param */

/* eslint-disable jsdoc/require-example */

/* eslint-disable jsx-a11y/alt-text */
import { CloudinaryImage as Image } from '@cloudinary/url-gen/assets/CloudinaryImage'
import React from 'react'

import { dprSourceSet } from './dprSourceSet'

export type CloudinaryElementProps = {
  image: Image
  quality?: string | number
  format?: string
}

export type CloudinaryImageProps = CloudinaryElementProps &
  React.ImgHTMLAttributes<HTMLImageElement> & {
    htmlWidth?: number | string
    htmlHeight?: number | string
  }

/**
 *
 * @see {@link https://cloudinary.com/documentation/javascript_integration|Cloudinary JavaScript SDK}
 * @see {@link https://cloudinary.com/documentation/javascript_image_transformations|JavaScript SDK image transformations}
 *
 *
 * @example
 * import { fill } from '@cloudinary/url-gen/actions/resize'
 * import { CloudinaryImage } from '@/cloudinary/CloudinaryImage'
 * import { cloudinary } from '@/cloudinary/cloudinary'
 *
 * <CloudinaryImage
 *   width="90px"
 *   image={cloudinary
 *     .image('system/arrow')
 *     .resize(fill().width(90))}
 * />
 *
 * @example
 * import { Image } from '@masterplandev/ui'
 * import { fill } from '@cloudinary/url-gen/actions/resize'
 * import { CloudinaryImage } from '@/cloudinary/CloudinaryImage'
 * import { cloudinary } from '@/cloudinary/cloudinary'
 *
 * <Image
 *   as={CloudinaryImage}
 *   image={cloudinary
 *     .image('system/invite-user.jpg')
 *     .resize(fill().width(518))}
 *   objectFit="cover"
 * />
 *
 */
export function CloudinaryImage({
  image,
  quality = 'auto:eco',
  format = 'auto',
  ...props
}: CloudinaryImageProps) {
  image.quality(quality).format(format)

  // use htmlWidth, htmlHeight from <Image /> component from ui package
  const { htmlWidth, htmlHeight, ...imageProps } = props

  return (
    <img
      width={htmlWidth}
      height={htmlHeight}
      {...imageProps}
      src={image.toURL()}
      srcSet={dprSourceSet(image)}
    />
  )
}

type CloudinarySourceProps = CloudinaryElementProps &
  React.SourceHTMLAttributes<HTMLSourceElement>

export function CloudinarySource({
  image,
  quality = 'auto:eco',
  format = 'auto',
  ...sourceAttributes
}: CloudinarySourceProps) {
  image.quality(quality).format(format)

  return <source srcSet={dprSourceSet(image)} {...sourceAttributes} />
}
