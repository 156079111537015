import { handleActions } from 'redux-actions'

import actions from '@/actions'

import topicReducer from './reducers/topic.reducer'

const initialState = {}

export default handleActions(
  {
    ...topicReducer,
    [actions.topic.clearAll]: () => initialState,
  },
  initialState,
)
