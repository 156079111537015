import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import actions from '@/actions'
import usePropsSelector from '@/core/utils/redux/usePropsSelector'
import toMatchParams from '@/lecture/utils/toMatchParams'

import { dataSelector } from '../selectors'

export default function useTopic({ slug: topic, existingTopic } = {}) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!existingTopic && topic) {
      dispatch(actions.topic.getIfRequired({ topic }))
    }
  }, [dispatch, topic, existingTopic])

  // If existing topic is specified, do not subscribe for needless re-renders.
  const topicArgs = existingTopic ? {} : toMatchParams({ topic })
  const topicData = usePropsSelector(dataSelector, topicArgs)

  return existingTopic ?? topicData
}
