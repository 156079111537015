import * as Sentry from '@sentry/react'
import Bowser from 'bowser'
import { cloneDeepWith, contains, getOr, merge, pipe } from 'lodash/fp'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import posthog from 'posthog-js'
import { Userpilot } from 'userpilot'

import { eventTracker } from '@masterplandev/utils'

import { env } from '@/env'

import { SENTRY_SECTIONS, TRACK_EXIT } from './core/constants/constants'
import requestSanitizer from './core/utils/logrocket/requestSanitizer'
import trackExit from './core/utils/tracking/trackExit'

const {
  APP_VERSION,
  REACT_APP_DOMAIN,
  REACT_APP_LOGROCKET_KEY,
  REACT_APP_SENTRY_DSN,
  MODE,
  REACT_APP_CIRCLE_SHA1,
  REACT_APP_API_URL_PREFIX,
  REACT_APP_USERPILOT_TOKEN,
  REACT_APP_MIXPANEL_KEY,
  REACT_APP_MIXPANEL_DEBUG,
  REACT_APP_PUBLIC_POSTHOG_KEY,
  REACT_APP_PUBLIC_POSTHOG_API_HOST,
  REACT_APP_PUBLIC_POSTHOG_UI_HOST,
} = env

const shouldIgnoreError = (error) => {
  if (error && contains('https://fast.wistia.com', error.stack)) {
    return true
  }
  return false
}

if (REACT_APP_MIXPANEL_KEY) {
  const referringDomain = (referrer) => {
    const split = referrer.split('/')
    return split.length >= 3 ? split[2] : ''
  }
  const { browser, os } = Bowser.parse(window.navigator.userAgent)
  const { width, height } = window.screen
  const referrer = getOr('$direct', 'referrer', document)
  // @ts-ignore
  eventTracker.set_config({ debug: REACT_APP_MIXPANEL_DEBUG })
  // @ts-ignore
  eventTracker.on_error((error) => {
    if (error?.message !== 'Network Error') {
      Sentry.captureException(error, {
        level: 'error',
        tags: { section: SENTRY_SECTIONS.eventTracking },
      })
    }
  })
  // @ts-ignore
  eventTracker.register({
    mp_lib: 'web',
    $os: os.name,
    $browser: browser.name,
    $browser_version: parseInt(browser.version || '0', 10),
    $initial_referrer: referrer,
    $initial_referring_domain: referringDomain(referrer),
    $screen_height: height,
    $screen_width: width,
  })
  // @ts-ignore
  eventTracker.time_event(TRACK_EXIT)
  // @ts-ignore
  eventTracker.init(REACT_APP_MIXPANEL_KEY)

  window.addEventListener('beforeunload', () => trackExit())
}

if (REACT_APP_LOGROCKET_KEY) {
  LogRocket.init(REACT_APP_LOGROCKET_KEY, {
    network: { requestSanitizer },
    dom: {
      // @ts-ignore
      inputSanitizer: true,
    },
    shouldCaptureIP: false,
  })
  LogRocket.getSessionURL((sessionURL) => {
    // @ts-ignore
    eventTracker.register({ sessionURL })
  })
  setupLogRocketReact(LogRocket)
}

// @ts-ignore
if (window.newrelic) {
  // eslint-disable-next-line no-unused-expressions
  // @ts-ignore
  window.newrelic.setErrorHandler(shouldIgnoreError)
  window.addEventListener('unhandledrejection', (event) => {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    window.newrelic.noticeError(event?.detail?.reason?.error, { event })
  })
}

if (REACT_APP_SENTRY_DSN) {
  const apiBaseUrl = `${window.location.origin}${REACT_APP_API_URL_PREFIX}`
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        block: ['[data-private]'],
        networkDetailAllowUrls: [window.location.origin],
      }),
    ],
    tracePropagationTargets: [apiBaseUrl],
    environment: MODE,
    release: REACT_APP_CIRCLE_SHA1,
    tracesSampleRate: MODE === 'production' ? 0.25 : 1.0,
    replaysSessionSampleRate: MODE === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event, hint) => {
      if (shouldIgnoreError(hint?.originalException)) {
        return null
      }

      const removeCertificateContent = cloneDeepWith((value) =>
        contains('<title>Certificate</title>', value)
          ? '[Certificate Content]'
          : undefined,
      )

      const addLogRocketURL = merge({
        extra: { sessionURL: LogRocket.sessionURL },
      })

      return pipe(addLogRocketURL, removeCertificateContent)(event)
    },
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // newrelic
      /newrelic\.com\//i,
      // logrocket
      /logrocket\.io\//i,
    ],
  })

  Sentry.setContext('Environment Variables', {
    MODE,
    APP_VERSION,
    REACT_APP_DOMAIN,
    REACT_APP_API_URL_PREFIX,
  })
}

if (REACT_APP_USERPILOT_TOKEN) {
  Userpilot.initialize(REACT_APP_USERPILOT_TOKEN)
}

if (REACT_APP_PUBLIC_POSTHOG_KEY) {
  posthog.init(REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: REACT_APP_PUBLIC_POSTHOG_API_HOST,
    ui_host: REACT_APP_PUBLIC_POSTHOG_UI_HOST,
    persistence: 'localStorage+cookie',
  })
}
