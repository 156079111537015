import { getOr, omit } from 'lodash/fp'

import {
  TRACK_ADDITIONAL_MATERIALS_DOWNLOAD,
  TRACK_LECTURE_CONTINUE,
  TRACK_LECTURE_END,
  TRACK_LECTURE_QUIZ_END,
  TRACK_LECTURE_QUIZ_START,
  TRACK_LECTURE_RESTART,
  TRACK_LECTURE_START,
  TRACK_TEXT_LECTURE_LINK_CLICK,
} from '@/core/constants/constants'
import track from '@/core/utils/tracking/track'
import { topicIdSelector } from '@/topic/selectors'

import {
  universalLectureHasQuizSelector,
  universalLectureIdSelector,
} from '../selectors/universalLecture.selectors'

const trackLectureAction = (eventTitle, params) => (dispatch, getState) => {
  const state = getState()
  const lectureId = universalLectureIdSelector(state, params)

  const event = { lecture_id: lectureId }

  if (eventTitle === TRACK_LECTURE_END) {
    event.has_quiz = universalLectureHasQuizSelector(state, params)
  }

  if (params.learnpathId) {
    track(eventTitle, { learnpath_id: params.learnpathId, ...event })
  } else {
    track(eventTitle, { topic_id: topicIdSelector(state, params), ...event })
  }
}

const trackLectureAdditionalMaterials =
  (eventTitle, params) => (dispatch, getState) => {
    const state = getState()

    const event = {
      lecture_id: universalLectureIdSelector(state, params),
      target_url: getOr('', 'targetUrl', params),
    }

    if (params.learnpathId) {
      track(eventTitle, { learnpath_id: params.learnpathId, ...event })
    } else {
      track(eventTitle, { topic_id: topicIdSelector(state, params), ...event })
    }
  }

export default {
  trackLectureStart: (params) =>
    trackLectureAction(TRACK_LECTURE_START, params),

  trackLectureContinue: (params) =>
    trackLectureAction(TRACK_LECTURE_CONTINUE, params),

  trackLectureEnd: (params) => trackLectureAction(TRACK_LECTURE_END, params),

  trackLectureRestart: (params) =>
    trackLectureAction(TRACK_LECTURE_RESTART, params),

  trackLectureQuizStart: (params) => (dispatch, getState) => {
    const state = getState()

    const lectureId = universalLectureIdSelector(state, params)
    const topicId = topicIdSelector(state, params)

    track(TRACK_LECTURE_QUIZ_START, {
      topic_id: topicId,
      lecture_id: lectureId,
      ...omit(['match', 'suffix'], params),
    })
  },

  trackLectureQuizEnd:
    (params, scores, totalFundamentalScore) => (dispatch, getState) => {
      const state = getState()

      const lectureId = universalLectureIdSelector(state, params)
      const topicId = topicIdSelector(state, params)

      track(TRACK_LECTURE_QUIZ_END, {
        topic_id: topicId,
        lecture_id: lectureId,
        ...(scores && {
          points: totalFundamentalScore,
          maximum: scores.max,
          percentage: (totalFundamentalScore / scores.max) * 100.0,
        }),
      })
    },

  trackLectureAdditionalMaterialsDownload: (params) =>
    trackLectureAdditionalMaterials(
      TRACK_ADDITIONAL_MATERIALS_DOWNLOAD,
      params,
    ),

  trackTextLectureLinkClick: (params) =>
    trackLectureAdditionalMaterials(TRACK_TEXT_LECTURE_LINK_CLICK, params),
}
