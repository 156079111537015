import { getActionTypes } from 'redux-axios-middleware'

import actions from '@/actions'
import mergeState from '@/core/utils/redux/mergeState'

import normalizeTopicLectures from '../utils/normalizeTopicLectures'

const [, TOPIC_GET_SUCCESS] = getActionTypes({
  type: actions.topic.get,
})

export default {
  [TOPIC_GET_SUCCESS]: (state, { payload: { data: topic } }) => {
    // Normalise all lectures' progress from topic.
    return mergeState(state, normalizeTopicLectures(state, topic))
  },
}
