import { get, size } from 'lodash/fp'

import { getPercentage } from '@masterplandev/utils'

import useTopic from './useTopic'

export default function useTopicCompletionProgress({ slug }) {
  const topic = useTopic({ slug })

  return (
    (getPercentage(
      get('progress.num_completed_lectures', topic),
      size(topic?.lectures),
    ) || 0) / 100
  )
}
