import bookmarked from '@/bookmarked/actions'
import channels from '@/channels/actions'
import company from '@/company/actions'
import core from '@/core/actions'
import dashboard from '@/dashboard/actions'
import learngroup from '@/learngroup/actions'
import learnpathsAssigned from '@/learnpaths-assigned/actions'
import mediaSummary from '@/learnpaths-media/actions'
import learnpaths from '@/learnpaths/actions'
import lecture from '@/lecture/actions'
import locale from '@/locale/actions'
import poll from '@/poll/actions'
import quiz from '@/quiz/actions'
import scorm from '@/scorm/actions'
import signup from '@/signup/actions'
import topic from '@/topic/actions'
import user from '@/user/actions'

export default {
  bookmarked,
  channels,
  company,
  core,
  dashboard,
  learngroup,
  learnpaths,
  learnpathsAssigned,
  lecture,
  locale,
  mediaSummary,
  poll,
  quiz,
  scorm,
  signup,
  topic,
  user,
}
