/* eslint-disable lodash-fp/no-extraneous-function-wrapping */

/* eslint-disable jsdoc/require-param */
import { get, head, intersection, map, pipe, replace, uniq } from 'lodash/fp'

import type { AppLocale } from '../types/Locale'

/**
 * Returns the supported locale based on a list of user-preferred languages
 * defined in the browser. Returns the first in the supported list
 * if none of the preferred ones are in the supported list.
 *
 * @returns {string} Preferred locale supported by the app
 *
 * @example
 * getLocaleFromNavigator(['de', 'en'])
 * // returns 'en'
 */
export function getLocaleFromNavigator(
  supportedLocale: readonly AppLocale[],
): string {
  const defaultLocale = head(supportedLocale)
  const preferredLocale = pipe(
    get<Navigator, 'languages'>('languages'),
    map(replace(/-.+/, '')),
    uniq,
    intersection(supportedLocale) as () => AppLocale[],
    head,
  )(window.navigator)

  if (!defaultLocale) {
    throw new Error(
      'The list of supported languages must contain at least one language',
    )
  }

  return preferredLocale || defaultLocale
}
