import { find, get, getOr } from 'lodash/fp'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { passProps } from '@masterplandev/utils'

import { generateBasicSelectors } from '@/core/selectors'
import cachedKeyCreator from '@/core/utils/redux/cachedKeyCreator'
import { localeSelector } from '@/locale/selectors'
import { fullnameSelector, keycloakIdSelector } from '@/user/selectors'

import buildApiUrl from '../utils/buildApiUrl'
import { rootSelector } from './scorm.selectors'
import { scormPackagesListSelector } from './scormPackages.selectors'

export const scormPackageSelectors = generateBasicSelectors(
  createCachedSelector([rootSelector, passProps], (root, { scormPackageId }) =>
    getOr({}, buildApiUrl.package(scormPackageId), root),
  )(cachedKeyCreator),
)

// Packages are available from /package endpoint and /packages endpoint thus
// some normalisation is required. Since it's hard to gather list from the reducer
// root, it's better to keep updating the list under /packages. Also API from /package
// usually have superset of attributes from /packages and that's why updating the latter
// will not cause data integrity issues.
scormPackageSelectors.data = createCachedSelector(
  [scormPackagesListSelector, passProps],
  (scormPackagesList: any, { scormPackageId }: Record<string, string> = {}) =>
    find({ id: scormPackageId }, scormPackagesList),
)(cachedKeyCreator)

export const scormPackageStatusSelector = createSelector(
  scormPackageSelectors.data,
  get('status'),
)

export const scormPackageBytesSelector = createSelector(
  scormPackageSelectors.data,
  get('bytes'),
)

export const scormBaseDataModelSelector = createSelector(
  [keycloakIdSelector, fullnameSelector, localeSelector],
  (id, fullname, language) => ({
    'cmi.core.student_id': id,
    'cmi.core.student_name': fullname,
    'cmi.student_preference.language': language,
    'cmi.learner_id': id,
    'cmi.learner_name': fullname,
    'cmi.learner_preference.language': language,
  }),
)
