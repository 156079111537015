import {
  get,
  getOr,
  includes,
  intersection,
  isEmpty,
  map,
  mapValues,
} from 'lodash/fp'
import { combineActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import actions from '@/actions'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeState from '@/core/utils/redux/mergeState'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

const [, POST_BOOKMARKED_LECTURES_SUCCESS] = getActionTypes({
  type: actions.bookmarked.lectures.post,
})
const [, DELETE_BOOKMARKED_LECTURES_SUCCESS] = getActionTypes({
  type: actions.bookmarked.lectures.delete,
})

const BOOKMARKED_LECTURES_CHANGED = combineActions(
  POST_BOOKMARKED_LECTURES_SUCCESS,
  DELETE_BOOKMARKED_LECTURES_SUCCESS,
)

const [GET_REQUEST, GET_SUCCESS, GET_ERROR] = getActionTypes({
  type: actions.topic.get,
})

export default {
  [GET_REQUEST]: mergeRequestAction,
  [GET_SUCCESS]: mergeSuccessAction,
  [GET_ERROR]: mergeErrorAction,
  [BOOKMARKED_LECTURES_CHANGED]: (state, { meta }) => {
    const modifiedPath = 'previousAction.payload.request.data.lectures_uids'
    const modifiedUIDs = getOr([], modifiedPath, meta)

    const type = get('previousAction.type', meta)
    const bookmarked = type === 'BOOKMARKED/LECTURES/POST'

    return mapValues((substate) => {
      const topicUids = map('uid', substate.data.lectures)
      const changedWithinTopic = intersection(modifiedUIDs, topicUids)

      if (isEmpty(changedWithinTopic)) {
        return substate
      }

      const updated = map(
        (lecture) =>
          includes(lecture.uid, changedWithinTopic)
            ? { ...lecture, bookmarked }
            : lecture,
        substate.data.lectures,
      )

      return mergeState(substate, { data: { lectures: updated } })
    })(state)
  },
}
