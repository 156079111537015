import { LOCATION_CHANGE } from 'connected-react-router'
import { get, isEmpty, isEqual, omit } from 'lodash/fp'
import { combineActions, handleActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import { anyTrue } from '@masterplandev/utils'

import actions from '@/actions'
import { queryClient } from '@/core/utils/react-query/queryClient'
import mergeState from '@/core/utils/redux/mergeState'
import { usersQueries } from '@/http/api/users'

const initialState = {
  fetching: false,
  fetched: false,
  failed: false,
  fresh: false,
  data: {},
  account: {},
}

const [GET_REQUEST, GET_SUCCESS, GET_ERROR] = getActionTypes({
  type: actions.user.get,
})
const [PUT_REQUEST, PUT_SUCCESS, PUT_ERROR] = getActionTypes({
  type: actions.user.put,
})
const [EMAIL_PUT_REQUEST] = getActionTypes({
  type: actions.user.email.put,
})
const [, LOGOUT_SUCCESS] = getActionTypes({ type: actions.user.logout })

export const REQUEST_ACTIONS = [GET_REQUEST, PUT_REQUEST, EMAIL_PUT_REQUEST]

export const SUCCESS_ACTIONS = [GET_SUCCESS]

export const ERROR_ACTIONS = [GET_ERROR]

export default handleActions(
  {
    [LOGOUT_SUCCESS]: (state) => ({
      ...state,
      data: {},
    }),
    [combineActions(...REQUEST_ACTIONS)]: (state, { options = {} }) => ({
      ...state,
      fetching: !options.ignoreResponse,
    }),
    [combineActions(...SUCCESS_ACTIONS)]: (state, { payload: { data } }) => {
      queryClient.setQueryData(usersQueries.usersCurrentDetails(), data)

      return {
        ...state,
        data,
        fetching: false,
        fetched: true,
        fresh: true,
        failed: false,
      }
    },
    [combineActions(...ERROR_ACTIONS)]: (state, { error }) => ({
      ...state,
      data: {},
      fetching: false,
      failed: true,
      fresh: true,
      error: get('response.data.message', error),
    }),
    [combineActions(PUT_ERROR)]: (state, { error }) => ({
      ...state,
      fetching: false,
      failed: true,
      fresh: true,
      error:
        get('response.data.messages', error) ||
        get('response.data.message', error),
    }),
    [PUT_SUCCESS]: (state, { payload: { data } }) => {
      queryClient.invalidateQueries(usersQueries.usersDetails())
      queryClient.invalidateQueries(usersQueries.usersCurrentDetails())

      return {
        ...state,
        data: {
          ...data,
          tokens: state.data.tokens,
        },
        fetching: false,
        fetched: true,
        fresh: true,
        failed: false,
        error: null,
      }
    },
    // Clears updated_password bool as soon as user leaves /company/settings or /user/settings page
    [LOCATION_CHANGE]: (state, { payload: { location } }) =>
      anyTrue([
        location.pathname.endsWith('/settings'),
        isEmpty(state.data),
        state.data.updated_password === false,
      ])
        ? state
        : mergeState(state, {
            data: { updated_password: false },
          }),
    [actions.user.updateEmail]: (state, { payload }) =>
      mergeState(state, { data: { email: payload } }),
    [actions.user.updateAccount]: (state, { payload }) => {
      const fields = ['exp', 'iat', 'jti']
      const payloadWithoutPeriodicInfo = omit(fields, payload)
      const accountWithoutPeriodicInfo = omit(fields, state.account)

      // Do not update user in case only those three fields above changed (they change every 10 seconds or so).
      if (isEqual(accountWithoutPeriodicInfo, payloadWithoutPeriodicInfo)) {
        return state
      }

      queryClient.invalidateQueries(usersQueries.usersDetails())
      queryClient.invalidateQueries(usersQueries.usersCurrentDetails())
      return mergeState(state, { account: payload })
    },
  },
  initialState,
)
