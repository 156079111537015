import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import React, { ReactNode } from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import { AuthProvider } from '@/auth/components/AuthProvider/AuthProvider'
import { UIProvider } from '@/core/components/UIProvider'
import configureStore from '@/core/store'
import { queryClient } from '@/core/utils/react-query/queryClient'
import LocaleProvider from '@/locale/components/LocaleProvider'
import { ScormAuthProvider } from '@/scorm/components/ScormAuthProvider/ScormAuthProvider'
import isScormEnabled from '@/scorm/utils/isScormEnabled'
import { NotificationsProvider } from '@/user/components/Notifications/NotificationsProvider/NotificationsProvider'

import { historyListener } from '../historyListener'
import { FeaturesProvider } from './FeaturesProvider'

const history = createBrowserHistory()
const store = configureStore({ history })

history.listen(historyListener)

function AuthProviders({ children }: React.PropsWithChildren<object>) {
  if (isScormEnabled()) {
    return <ScormAuthProvider>{children}</ScormAuthProvider>
  }

  return <AuthProvider>{children}</AuthProvider>
}

interface AppProvidersProps {
  children: ReactNode
  locale?: string
  messages?: Record<string, string>
}

export function AppProviders({
  children,
  locale,
  messages = {},
}: AppProvidersProps) {
  return (
    <UIProvider>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <PostHogProvider client={posthog}>
            <LocaleProvider customMessages={messages} localeOverride={locale}>
              <ConnectedRouter history={history}>
                <FeaturesProvider>
                  <AuthProviders>
                    <NotificationsProvider>{children}</NotificationsProvider>
                  </AuthProviders>
                </FeaturesProvider>
              </ConnectedRouter>
            </LocaleProvider>
            <ReactQueryDevtools position="bottom-right" />
          </PostHogProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </UIProvider>
  )
}
