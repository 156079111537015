/**
 * Build URL to Keycloak plugin that extends it's API with endpoint that allow for
 * obtaining browser session for user for it's valid access token
 *
 * @see https://github.com/masterplandev/keycloak-browser-session-for-access-token-endpoint
 */

/* eslint-disable jsdoc/require-param */

/* eslint-disable jsdoc/require-example */
import type { KeycloakInstance } from 'keycloak-js'

/**
 * Create url to keycloak browser session plugin to
 * authorize user with token and create cookies
 *
 */
export function createBrowserSessionUrl(
  keycloak: KeycloakInstance,
  token: string,
  redirectUri: string,
) {
  const authRealmUrl = `${keycloak.authServerUrl}/realms/${encodeURIComponent(
    keycloak.realm || '',
  )}`

  const sessionAuthPluginUrl = new URL(`${authRealmUrl}/browser-session`)
  sessionAuthPluginUrl.searchParams.set('accessToken', token)
  sessionAuthPluginUrl.searchParams.set(
    'encodedRedirectUri',
    btoa(redirectUri.replace(authRealmUrl, '')),
  )

  return sessionAuthPluginUrl
}

/**
 *  Create url to the authorization service to create cookie-based auth session
 */
export function createLoginWithTokenUrl(
  keycloak: KeycloakInstance,
  accessToken: string,
  redirectUrl?: string,
) {
  const loginUrl = keycloak.createLoginUrl({
    redirectUri: redirectUrl ?? new URL(window.location.href).toString(),
  })

  return createBrowserSessionUrl(keycloak, accessToken, loginUrl)
}
